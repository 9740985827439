import { Rect } from './rectangle';
import { clamp01 } from './utility';

export function lerp(a: number, b: number, t: number): number {
  return a + (b - a) * t;
}

export function bilerp(p00: number, p01: number, p10: number, p11: number, tx: number, ty: number): number {
  const u = 1 - tx;
  const v = 1 - ty;
  return u * (v * p00 + ty * p01) + tx * (v * p10 + ty * p11);
}

export function quadraticLerp(p0: number, p1: number, p2: number, t: number): number {
  const invT = 1 - t;
  return invT * invT * p0 + 2 * invT * t * p1 + t * t * p2;
}

export function cubicLerp(p0: number, p1: number, p2: number, p3: number, t: number): number {
  const t2 = t * t;
  const t3 = t2 * t;

  const c = 3 * (p1 - p0);
  const b = 3 * (p2 - p1) - c;
  const a = p3 - p0 - c - b;

  return a * t3 + b * t2 + c * t + p0;
}

export function hornerCubicLerp(p0: number, p1: number, p2: number, p3: number, t: number): number {
  const c = 3 * (p1 - p0);
  const b = 3 * (p2 - p1) - c;
  const a = p3 - p0 - c - b;

  return ((a * t + b) * t + c) * t + p0;
}

export function hermiteLerp(p0: number, p1: number, t0: number, t1: number, t: number): number {
  const t2 = t * t;
  const t3 = t2 * t;

  const h1 = 2 * t3 - 3 * t2 + 1;
  const h2 = -2 * t3 + 3 * t2;
  const h3 = t3 - 2 * t2 + t;
  const h4 = t3 - t2;

  return h1 * p0 + h2 * p1 + h3 * t0 + h4 * t1;
}

export function catmullRomLerp(p0: number, p1: number, p2: number, p3: number, t: number): number {
  const t2 = t * t;
  const t3 = t2 * t;

  const a = -0.5 * p0 + 1.5 * p1 - 1.5 * p2 + 0.5 * p3;
  const b = p0 - 2.5 * p1 + 2 * p2 - 0.5 * p3;
  const c = -0.5 * p0 + 0.5 * p2;
  const d = p1;

  return a * t3 + b * t2 + c * t + d;
}

export function smoothStep(a: number, b: number, t: number): number {
  t = clamp01(t);
  t = -2 * t * t * t + 3 * t * t;
  return b * t + a * (1 - t);
}

export function smootherStep(a: number, b: number, t: number): number {
  t = clamp01(t);
  t = t * t * t * (t * (t * 6 - 15) + 10);
  return b * t + a * (1 - t);
}

export function rectLerp(rect1: Rect, rect2: Rect, t: number, out?: Rect): Rect {
  out = out || { x: 0, y: 0, width: 0, height: 0 };
  out.x = lerp(rect1.x, rect2.x, t);
  out.y = lerp(rect1.y, rect2.y, t);
  out.width = lerp(rect1.width, rect2.width, t);
  out.height = lerp(rect1.height, rect2.height, t);
  return out;
}

export function elasticLerp(a: number, b: number, t: number, period = 0.3): number {
  const s = (period / (2 * Math.PI)) * Math.asin((b - a) / 1);
  return a + Math.pow(2, -10 * t) * Math.sin(((t - s) * (2 * Math.PI)) / period) * (b - a);
}
